import './ChatWindow.css';
import { useEffect } from 'react';
import 'react-chat-elements/dist/main.css';
import { RootState } from '../../app/store';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  MessageList,
  MessageBox
} from 'react-chat-elements';
import {
  Box,
  TextField,
  Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  selectMessages,
  sendPrompt,
  updateUserMessage,
  beginReset,
  confirmReset,
  cancelReset
} from './chatSlice';
import { TrackGoogleAnalyticsEvent } from '../analytics/';

export const ChatWindow = () => {
  const dispatch = useAppDispatch()
  const messages = useAppSelector((state: RootState) => state.chat.messages);
  const rawMessages = useAppSelector((state: RootState) => state.chat.rawMessages);
  const status = useAppSelector((state: RootState) => state.chat.status);
  const userMessage = useAppSelector((state: RootState) => state.chat.userMessage);
  const resetInProgress = useAppSelector((state: RootState) => state.chat.resetInProgress);

  const handleSend = () => {
    if (userMessage === '') {
      return;
    }
    TrackGoogleAnalyticsEvent('home', 'prompt_send', userMessage);
    dispatch(sendPrompt(userMessage));
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateUserMessage(event.target.value));
  };

  const scrollToBottom = () => {
    const mlistElement = document.getElementsByClassName('rce-mlist')[0];
    if (typeof mlistElement !== 'undefined') {
      mlistElement.scrollTop = mlistElement.scrollHeight;
    }
  };

  const handleResetBegin = (rawMessageIndex) => {
    dispatch(beginReset(rawMessageIndex));
  };

  const handleResetConfirm = (rawMessageIndex) => {
    dispatch(confirmReset(rawMessageIndex));
  };

  const handleResetCancel = () => {
    dispatch(cancelReset());
  };

  useEffect(() => {
    if (!resetInProgress) {
      scrollToBottom()
    }
  }, [messages]);

  const lastPilotMessage = messages.filter(message => message.position === 'left').pop();

  const messageListItems = messages.map((message, index) => {
    //console.log('@message', index, message);

    let messageOptions = null;
    const isLast = message === lastPilotMessage;
    if (!isLast && message.position === 'left' && message.type === 'text') {
      if (!message.resetInProgress) {
        messageOptions = (
          <div className="messageOptions">
            <Button variant="outlined" onClick={() => { handleResetBegin(message.rawMessageIndex) }}>Reset Here</Button>
          </div>
        );
      } else {
        messageOptions = (
          <div className="messageOptions">
            <Button variant="outlined" onClick={() => { handleResetConfirm(message.rawMessageIndex) }}>Confirm Reset</Button>
            <Button variant="outlined" onClick={() => { handleResetCancel() }}>Cancel</Button>
          </div>
        );
      }
    }
    
    const classes = [];
    if (message.resetInProgress) {
      classes.push('resetInProgress');
    }
    if (message.pendingDelete) {
      classes.push('pendingDelete');
    }
    const classNames = classes.join(' ');

    return (
      <MessageBox
        className={classNames}
        key={'mbox-'+index}
        title={message.title}
        position={message.position}
        type={message.type}
        text={
          <>
            <div dangerouslySetInnerHTML={{__html: message.text}} />
            { messageOptions }
          </>
        }>
      </MessageBox>
    );
  });

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="messageListContainer">

        {/* @ts-ignore TS2322 */}
        <div className="message-list">
          <div className="rce-mlist">
            { messageListItems } 
          </div>
        </div>
      </div>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField value={userMessage}
            size="small"
            placeholder="Enter your prompt here..."
            disabled={status === 'loading'}
            sx={{ width: '100%' }}
            onChange={handleTextChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSend();
              }
            }}
          />

        </Box>
        <LoadingButton variant="contained"
          disabled={userMessage === '' || resetInProgress}
          onClick={handleSend}
          loading={status === 'loading'}>
          Send
        </LoadingButton>
      </Box>
    </div>
  );
};
