import './Editor.css';
import Split from 'react-split-it';
import {
  useLiveRunner,
  LiveProvider,
  LiveEditor,
  LivePreview,
  LiveError,
} from 'react-live-runner';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

// To pass to react-live-runner:
import * as React from 'react';
import * as MaterialPkg from '@mui/material';
import * as LabPkg from '@mui/lab';
import * as IconsPkg from '@mui/icons-material';
// import * as MaterialStyles from '@mui/styles'
const importObj = Object.fromEntries(
  Object.entries(MaterialPkg).map(([key, value]) => [
    `@mui/material/${key}`,
    value,
  ])
) as {
  [K in keyof typeof MaterialPkg as `@mui/material/${K}`]: (typeof MaterialPkg)[K];
};
const scope = {
  import: {
    ...importObj,
    react: React,
    '@mui/material': MaterialPkg,
    '@mui/lab': LabPkg,
    '@mui/icons-material': IconsPkg,
  }
};

//const importObj: { [key: string]: object } = {};
/*
type ImportType = {
  [key: string]: any
};

const importObj: ImportType = {};
*/

/*
const importObj: Record<string, any> = {};
for (const component in MaterialPkg) {
  const key = `@mui/material/${component}`;
  importObj[key] = MaterialPkg[component];
}
*/

const Editor = () => {
  const code = useAppSelector((state: RootState) => state.chat.code);
  const resetInProgress = useAppSelector((state: RootState) => state.chat.resetInProgress);
  const dispatch = useAppDispatch();

  const leftContentContainerClasses = ['leftContentContainer'];
  if (resetInProgress) {
    leftContentContainerClasses.push('resetInProgress');
    const el = document.getElementsByClassName('editorContainer');
    if (el[0]) {
      el[0].scrollIntoView();
    }
  }

  return (
          <LiveProvider code={code} scope={scope}>
            <div className={leftContentContainerClasses.join(' ')}>
              {/* @ts-ignore TS2322 */}
              <Split direction="vertical">
                <LivePreview style={{ padding: '10px' }} />
                <div className="editorContainer">
                  <div className="editorCover" />
                  <LiveEditor style={{ minHeight: '100%', fontFamily: 'monospace' }} />
                </div>
              </Split>
            </div>
            <div className="errorContainer">
              <LiveError />
            </div>
          </LiveProvider>
  )
};

export default Editor;
