import './App.css';
import { ChatWindow } from './features/chat/ChatWindow';
import { TitleBar } from './features/titlebar/TitleBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Editor from './features/editor/Editor';
import Split from 'react-split-it';
import { useEffect } from 'react';
import { InitializeGoogleAnalytics } from './features/analytics/';
import { loadState } from './app/browser-storage/browser-storage';
import { reloadSavedState } from './features/chat/chatSlice';
import { useAppDispatch } from './app/hooks';

const defaultTheme = createTheme();


import { ChatState } from './features/chat/chatSlice';


function App() {
  const dispatch = useAppDispatch();
  
  const reloadSavedStateIfExists = () => {
    const existingState = loadState();
    if (typeof existingState !== 'undefined') {
      dispatch(reloadSavedState(existingState)); 
    }
  };

  useEffect(() => {
    InitializeGoogleAnalytics();
    reloadSavedStateIfExists();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* @ts-ignore TS2322 */}
      <Split style={{flexGrow: 1}} direction="horizontal">
        <div style={{ height: '100%'}}>
          <Editor />
        </div>
        <div className="rightContentContainer">
          <ChatWindow />
        </div>
      </Split>

    </ThemeProvider>

  )
}

export default App
