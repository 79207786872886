//import { Counter } from '../../features/counter/Counter'
//import SplitPane from '../react-split-pane';
import App from '../../App';
// import SimpleApp from '../../SimpleApp';

export const documentProps = {
  title: 'UI Pilot',
  description: 'Chat-based AI code generator that creates forms using Material UI'
}


export { Page }

function Page() {
  return (
    <>
      <App />
    </>
  )
}
